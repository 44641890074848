<template>
  <div class="listings">
    <div class="hgroup">
      <h2>{{title}}</h2>
      <p class="faded">Update and manage your listings here</p>
    </div>
    <div class="listings-wrapper">
      <div class="centered faded block" v-if="hasNoListingsFetched">
        <i class="icon icon-flag icon-large"></i>
        <p>Looking for your own listings... 🧐</p>
      </div>
      <div class="centered faded block" v-if="!hasNoListingsFetched && hasNoListings">
        <i class="icon icon-flag icon-large"></i>
        <p>There's nothing here yet. But why? 🤔</p>
        <router-link class="btn small" :to="{name: 'createListing'}">Create a listing now</router-link>
      </div>
      <mini-listing
        :key="id"
        v-for="(listing, id) in listings"
        :images="listing.images"
        :title="listing.title"
        :location="listing.location"
        :price="+listing.price"
        :price_type="listing.price_type"
        :space_type="listing.space_type"
        :bed_count="listing.bed_count"
      >
        <div class="actions">
          <router-link class="btn small" :to="{name: 'editListing', params: {id}}">Edit Listing</router-link>
        </div>
      </mini-listing>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";

import CreateListing from "./createListing.vue";
import db from "~/js/helpers/db";

import miniListing from "../listings/components/miniListing.vue";

export default {
  components: {
    CreateListing,
    miniListing
  },
  name: "myListings",
  data() {
    return {
      listings: {},
      title: "My Listings",
      hasNoListingsFetched: true,
    };
  },
  computed: {
    hasNoListings: function() {
      return isEmpty(this.listings);
    }
  },
  mounted: function() {
    let vm = this;

    db.firestore
      .collection(`listings`)
      .where("uid", "==", db.getUID())
      .orderBy('updated_at', 'desc')
      .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          vm.$set(vm.listings, doc.id, doc.data());
        });
        vm.hasNoListingsFetched = false;
      });
  }
};
</script>

<style lang='scss' scoped>
.listings {
  text-align: left;
}

.listings-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
}
</style>
