<template>
  <sidebar-layout>
    <div slot="sidebar">
      <div class="hgroup">
        <h2>
          Filters
          <span class="beta-info">
            These features are currently on beta,
            <br>and more will be available to you soon.
          </span>
        </h2>
      </div>

      <div class="filters">
        <label>
          <span class="desc">Minimum price</span>
          <input type="number" v-model="min" step="1000" min="0" @change="replaceRouteQuery">
        </label>
        <label>
          <span class="desc">Maximum price</span>
          <input
            type="number"
            v-model="max"
            step="1000"
            max="999999000"
            @change="replaceRouteQuery"
          >
        </label>
      </div>
    </div>
    <div slot="content">
      <transition name="mystay-transition-slide" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </sidebar-layout>
</template>

<script>
import sidebarLayout from "../layout/sidebarLayout";
import { debounce } from "lodash";

export default {
  components: {
    sidebarLayout
  },
  data: () => ({
    min: 0,
    max: 999999000
  }),
  methods: {
    replaceRouteQuery: debounce(function(e) {
      console.log(e);
      let vm = this;
      let { min, max } = vm;

      vm.$router.replace({
        name: "allListings",
        query: { min: +min, max: +max }
      });
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
.beta-info {
  font-size: 0.6em;
  display: block;
  opacity: 0.5;
}
.filters {
  padding: 1em;

  label {
    margin-bottom: 0.5em;

    input {
      width: 100%;
      text-align: right;
    }
  }
}
</style>
