<template>
  <sidebar-layout>
    <div slot="sidebar">
      <div class="hgroup">
        <h2>Dashboard</h2>
      </div>
      <router-link :to="{name:'createListing'}">Create Listing</router-link>
      <router-link :to="{name:'myListings'}">My Listings</router-link>
      <router-link :to="{name:'myProfile'}">Profile</router-link>
    </div>
    <div slot="content">
      <transition name="mystay-transition-slide" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </sidebar-layout>
</template>

<script>
import SidebarLayout from "../layout/sidebarLayout";
export default {
  components: {
    SidebarLayout
  },
  data() {
    return {
      title: "Dashboard content"
    };
  }
};
</script>