<template>
  <div class="listing-item">
    <div class="image-wrapper">
      <div
        class="image"
        :key="image"
        v-if="currentImage == i"
        v-for="(image, i) in images"
        :style="{backgroundImage:`url(${image})`}"
      ></div>
      <div
        class="image"
        v-if="images.length == 0"
        :style="{backgroundImage:`url('/assets/placeholder.svg')`}"
      ></div>
    </div>
    <div class="desc-grid">
      <div class="primary">
        <h3 class="title">{{(title || 'Title of your listing')}}</h3>
        <span class="location">
          <i class="icon icon-map-pin pr"></i>
          <a
            class="location-link"
            :href="getMapsURL(location)"
            target="_blank"
          >{{location || 'Location'}}</a>
        </span>
      </div>
      <div class="secondary-desc">
        <span class="price" v-html="formattedPrice"></span>
        <div class="settings">
          <span>{{space_type}}</span> &bull;
          <span>{{bedCountSummary}}</span> &bull;
          <span>{{price_type}}</span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { isNumber } from "lodash";
export default {
  props: {
    images: Array,
    title: String,
    location: String,
    price: Number,
    price_type: String,
    space_type: String,
    bed_count: Array
  },
  data: () => ({
    unit: "Rs.",
    currentImage: 0
  }),
  methods: {
    thousandSeparated: val => val.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    getGridArea: (i, n) => {
      switch (n) {
        case 1:
          return "1 / 1 / 1 / 3";
        case 2:
          return "auto";
        default:
          if (i == 1) {
            return `1 / 1 / ${n} / 2`;
          }
          return "auto";
      }
    },
    getMapsURL: location => {
      if (!location) {
        return;
      }

      let _location = encodeURI(location);
      return `https://www.google.com/maps/place/${_location}`;
    }
  },
  computed: {
    formattedPrice: function() {
      let _value = this.price;
      if (!_value || !isNumber(_value)) {
        return "<small>Price not set</small>";
      }

      let _amount = _value.toFixed(2).split(".");

      return `${this.unit} ${this.thousandSeparated(_amount[0])}.<small>${
        _amount[1]
      }</small>`;
    },
    bedCountSummary: function() {
      if (!this.bed_count) {
        return "...";
      }
      let _total = this.bed_count.reduce((acc, curr) => {
        return acc + +curr.count;
      }, 0);

      return _total == 1 ? "1 bed" : `${_total} beds`;
    }
  }
};
</script>


<style lang="scss" scoped>
@import "~/scss/variables.scss";
.listing-item {
  border-radius: $default-border-radius;
  overflow: hidden;
  width: 100%;
  box-shadow: $box-shadow-light;

  .image-wrapper {
    background: $border-light;
    display: block;
    height: 300px;
    width: 100%;

    .image {
      background: lighten($border-light, 30) center;
      background-size: cover;
      width: 100%;
      box-shadow: $box-shadow;
      height: 100%;
    }
  }
  .desc-grid {
    border-radius: 0 0 $default-border-radius $default-border-radius;
    border: $border-light solid thin;
    border-top: none;
    display: block;
    padding: 1em;
    flex-wrap: wrap;

    flex-grow: 1;
    .title {
      color: $default;
      margin: 0;
      margin-bottom: 0.3em;
      font-size: 1.2em;
      display: block;

      &::first-letter {
        text-transform: uppercase;
      }
    }
    .location {
      display: block;
      text-transform: capitalize;
      font-size: 1em;
      color: rgba($default, 0.9);

      i {
        padding-right: 0.3em;
      }

      .location-link {
        font-weight: normal;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .secondary-desc {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      margin-right: 1em;
      .price {
        color: $accent;
        display: block;
        margin-top: 0em;
        font-size: 1.2em;
      }
      .settings {
        opacity: 0.7;
        font-size: 0.95em;
        margin-top: 0.2em;

        span {
          white-space: nowrap;
        }
      }
    }

    .actions {
      margin-left: 1em;
    }
  }
  @media screen and (max-width: 1200px) {
    .desc-grid {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;

      .primary {
        flex-grow: 0 !important;
        width: 100%;
      }

      .secondary-desc {
        align-items: flex-start !important;
        width: 100%;
        margin-right: 0;

        .price {
          width: 100%;
          padding-top: 0.5em;
          margin-top: 0.5em;
          display: block;
        }
        .settings {
          text-align: left;
        }
      }

      .actions {
        margin-left: 0;
        width: 100%;
        display: block;
        padding-top: 0.5em;
        margin: 1em 0;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    & {
      display: flex;
    }
    .image-wrapper {
      max-width: 300px;
      height: 150px;
    }

    .desc-grid {
      display: flex;
      align-items: center;
      border-radius: 0 $default-border-radius $default-border-radius 0;
      border: $border-light solid thin;
      border-left: 0;
      padding: 0 2vw;

      .primary {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      span.price {
        margin-top: 0;
      }
    }
  }
}
</style>
