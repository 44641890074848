import ProgressBar from './progressbar.vue';

export default {
  install(Vue, options = {}) {
    // create constructor
    let VueProgressBar = Vue.extend(ProgressBar);
    VueProgressBar.options = Object.assign(VueProgressBar.options, options);

    // create an instance of ProgressBar and mount it on an element
    let container = document.createElement('div');
    let body = document.querySelector('body').appendChild(container);

    let ProgressIndicator = new VueProgressBar().$mount(body);

    Vue.$showLoader = Vue.prototype.$showLoader = (id) => {
      ProgressIndicator.showLoader(id);
    };
    Vue.$hideLoader = Vue.prototype.$hideLoader = (id) => {
      ProgressIndicator.hideLoader(id);
    };
  }
};
