import Vue from 'vue';

import App from './components/App';
import router from './router';

import firebase from './helpers/firebase';

import VueNotify from './plugins/VueNotify';
import VueProgressBar from './plugins/VueProgressBar';
Vue.use(VueNotify);
Vue.use(VueProgressBar);

let app;

document.addEventListener('DOMContentLoaded', function() {
  firebase.auth().onAuthStateChanged(function(user) {
    if (!app) {
      app = new Vue({
        el: '#app',
        router,
        render: createEle => createEle(App)
      });
    }
  });
});
