<template>
  <div class="auth-center-plate">
    <form @submit.prevent="createUser">
      <div class="hgroup">
        <h1>Sign up</h1>
        <h3>
          Welcome to
          <span class="dark">myStay</span>.lk
        </h3>
      </div>
      <label>
        <span class="desc">Email</span>
        <input class="large" type="text" placeholder="Your email" v-model="email">
      </label>
      <label>
        <span class="desc">Password</span>
        <input class="large" type="password" placeholder="Your password" v-model="password">
      </label>
      <input class="call-to-action" type="submit" value="Create account" @click="createUser">
      <p>
        Already have an account?
        <router-link :to="{name: 'signin'}">Sign in here</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "signup",
  data: () => {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    createUser: function() {
      let vm = this;
      vm.$showLoader("auth_sign_up");

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          function(user) {
            vm.$notify(
              "success",
              "Successfully signed up",
              "Your account has been successfully created",
              "auth_sign_up"
            );
            vm.$router.replace({ name: "dashboard" });
            vm.$hideLoader("auth_sign_up");
          },
          function(err) {
            vm.$notify(
              "error",
              "Failed to sign up",
              err.message ||
                "There were some errors while creating your account",
              "auth_sign_up"
            );
            vm.$hideLoader("auth_sign_up");
          }
        );
    }
  }
};
</script>

<style lang="scss">
</style>
