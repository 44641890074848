<template>
  <div id="app-wrapper">
    <nav class="top-nav">
      <div class="nav-wrapper">
        <router-link :to="{name:'home'}" class="nav-brand">
          <img src="~/img/logo.svg" class="nav-logo px" alt>
          <transition name="mystay-slide-fade">
            <span v-if="!isHome" class="nav-logo-text">
              myStay<span class="faded">.lk</span>
            </span>
          </transition>
        </router-link>
        <div
          class="right-mobile-menu-btn"
          :class="{shown: isMobileMenuShown}"
          @click="toggleMenu()"
        >
          <transition name="mystay-menu-btn" mode="out-in">
            <i key="icon-close" v-if="isMobileMenuShown" class="icon icon-x"></i>
            <i key="icon-show" v-else class="icon icon-menu"></i>
          </transition>
        </div>
        <div class="right" :class="{'menu-shown': isMobileMenuShown}">
          <router-link :to="{name:'allListings'}">
            <i class="icon icon-compass pr"></i> Listings
          </router-link>
          <template v-if="currentUser == null">
            <router-link :to="{name:'signup'}">
              <i class="icon icon-user pr"></i>Create Account
            </router-link>
            <router-link :to="{name:'signin'}">
              <i class="icon icon-user pr"></i>Sign in
            </router-link>
          </template>
          <template v-else>
            <div class="hover-menu-wrapper">
              <router-link :to="{name:'dashboard'}">
                <i v-if="!currentUser.photoURL" class="icon icon-user profile-image"></i>
                <i
                  v-else
                  class="profile-image"
                  :style="{backgroundImage: `url(${currentUser.photoURL})`}"
                ></i>
                {{ currentUser.displayName || currentUser.email}}
              </router-link>
              <div class="hover-menu">
                <router-link :to="{name:'myListings'}">
                  <i class="icon icon-grid pr"></i>My Listings
                </router-link>
                <router-link :to="{name:'myProfile'}">
                  <i class="icon icon-user pr"></i>Profile
                </router-link>
                <a href="javascript:void(0)" @click="logout">
                  <i class="icon icon-power pr"></i>Sign out
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </nav>
    <div class="content">
      <transition name="mystay-transition" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      currentUser: firebase.auth().currentUser,
      isHome: true,
      isMobileMenuShown: false
    };
  },
  methods: {
    logout: function() {
      let vm = this;
      firebase
        .auth()
        .signOut()
        .then(() => {
          vm.$router.replace({ name: "home" });
        });
    },
    toggleMenu: function() {
      this.isMobileMenuShown = !this.isMobileMenuShown;
    }
  },
  watch: {
    $route: function(to, from) {
      this.isMobileMenuShown = false;
      this.isHome = to.name == "home";
    }
  },
  mounted: function() {
    let vm = this;
    firebase.auth().onAuthStateChanged(function(user) {
      vm.currentUser = user;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~/scss/variables.scss";

#app-wrapper {
  min-height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;

  nav.top-nav {
    position: fixed;
    background: $background;
    top: 0;
    height: 4em;
    border-bottom: solid thin $border-light;
    box-sizing: border-box;
    box-shadow: $box-shadow-light;
    z-index: 99999;
    width: 100%;

    .nav-wrapper {
      height: 100%;
      max-width: 1366px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a,
      a:link,
      .nav-brand:link {
        font-size: 1rem;
        color: $default;
        text-decoration: none !important;
        font-weight: normal;
        display: flex;
        align-items: center;

        img.nav-logo {
          height: 2em;
        }

        .nav-logo-text {
          color: $default;
          white-space: nowrap;

          .faded {
            color: $faded;
          }
        }

        .profile-image {
          background: rgba($default, 0.05) center;
          background-size: cover;
          border: solid thin $border-light;
          margin-right: 1em;
          padding: 1em;
          border-radius: 2em;
        }
      }

      .right-mobile-menu-btn {
        position: fixed;
        padding: 1.5em 2em;
        right: 0;
        top: 0;
        z-index: 100000000;
        pointer-events: none;
        transform: translateX(3em);
        transition: transform 0.1s ease-out;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          transition: transform 0.1s ease-out;
          pointer-events: all;
          transform: translateX(0px);

          &.shown {
            transform: translateX(-2.5em);
          }
        }
      }
      .right {
        height: 100%;
        display: flex;
        align-items: stretch;
        a,
        a.link {
          font-weight: normal;
          color: $default;
          text-decoration: none;
          padding: 0 2em;
          display: flex;
          align-items: center;
          transition: all 0.1s;
          border-bottom: solid thin transparent;

          &:hover,
          &.router-link-active {
            color: darken($accent, 5%);
            border-bottom: solid thin darken($accent, 5%);
          }
        }

        @media screen and (max-width: 768px) {
          opacity: 0;
          pointer-events: none;
          display: flex;
          align-items: flex-end;
          justify-content: start;

          &.menu-shown {
            pointer-events: all;

            padding: 4em 2em;
            flex-direction: column;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1000000;
            background: $light;
            opacity: 1;

            a {
              display: block;
              width: calc(100vw - 4em);
              padding: 1em 2em;
              box-sizing: border-box;
              border-right: solid thin transparent;

              i {
                float: right;
                margin-right: 0;
                padding-right: 0;
                margin-right: 0.5em;

                &.profile-image {
                  margin-right: 0em;
                  padding-right: 1em;
                }
              }

              &:hover,
              &.router-link-active {
                color: darken($accent, 5%);
                border-right: solid thin darken($accent, 5%);
                border-bottom-color: transparent;
              }
            }

            .hover-menu-wrapper {
              height: unset;
              position: relative;
              flex-direction: column;

              .hover-menu {
                width: 100%;
                position: relative;
                top: unset;
                display: block;
              }
            }
          }
        }
      }

      .hover-menu-wrapper {
        height: 100%;
        display: flex;
        align-items: stretch;
        position: relative;

        .hover-menu {
          position: absolute;
          top: calc(4em - 1px);
          width: 100%;
          right: 0;
          background: $light;
          box-shadow: $box-shadow-light;
          display: none;

          a {
            padding: 1em 2em;
          }
        }

        &:hover {
          .hover-menu {
            display: block;
            z-index: 100000;

            a {
              border-bottom: solid thin $border-light;
              &:hover {
                background: $accent;
                color: $light;
              }
            }
          }
        }
      }
    }
  }
  & > .content {
    padding-top: 4em;
    min-height: calc(100% - 4em);
  }
}
</style>
