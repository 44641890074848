<template>
  <div class="listing-item">
    <transition-group
      name="mystay-list"
      tag="div"
      class="image-grid"
      :class="{'has-images': listingImageLength > 0}"
    >
      <div
        class="image"
        :key="image"
        v-for="(image, i) in listingImages"
        :style="{backgroundImage:`url(${image})`, gridArea: getGridArea(i, listingImageLength)} "
      ></div>
    </transition-group>
    <div class="desc-grid">
      <div class="left">
        <h3 class="title">{{(listingTitle || getPlaceholder('title'))}}</h3>
        <span class="location">
          <i class="icon icon-map-pin pr"></i>
          <a
            class="location-link"
            :href="getMapsURL(listingLocation)"
            target="_blank"
          >{{listingLocation || getPlaceholder('location')}}</a>
        </span>
        <span class="hosted-by" v-if="listingUser">
          <i class="icon icon-user pr"></i>
          <a class="hosted-by-link" :href="getProfileLink(listingUser)" target="_blank">
            <small>hosted by</small>
            {{ listingUser.displayName || listingUser.email }}
          </a>
          <a
            class="btn x-small hosted-by-call"
            v-if="getProfilePhone(listingUser)"
            :href="getProfilePhone(listingUser)"
          >
            <i class="icon icon-phone"></i>
            Call
          </a>
          <a class="btn x-small hosted-by-call" v-else :href="getProfileEmail(listingUser)">
            <i class="icon icon-at-sign"></i>
            Email
          </a>
        </span>
      </div>
      <div class="right">
        <span class="price" v-html="formattedPrice"></span>
        <div class="settings">
          <span>{{listingSpaceType}}</span> &bull;
          <span>{{bedCountSummary}}</span> &bull;
          <span>{{listingPriceType}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isNumber } from "lodash";

import db from "~/js/helpers/db";

export default {
  props: {
    images: Array,
    title: String,
    location: String,
    price: Number,
    price_type: String,
    space_type: String,
    bed_count: Array,
    id: String,
    user: Object
  },
  data: function() {
    let _placeholders = {
      title: "...",
      location: "...",
      price: "..."
    };

    // for create/edit forms
    if (!this.id) {
      _placeholders = {
        title: "Title of your listing",
        location: "Location",
        price: "&lt;Not set yet&gt;"
      };
    }

    return {
      listing: {},
      unit: "Rs.",
      placeholders: _placeholders
    };
  },
  methods: {
    thousandSeparated: val => val.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    getPlaceholder: function(element) {
      return this.placeholders[element];
    },
    getGridArea: (i, n) => {
      switch (n) {
        case 1:
          return "1 / 1 / 1 / 3";
        case 2:
          return "auto";
        default:
          if (i == 0) {
            return `1 / 1 / ${n} / 2`;
          }
          return "auto";
      }
    },
    getMapsURL: location => {
      if (!location) {
        return;
      }

      let _location = encodeURI(location);
      return `https://www.google.com/maps/place/${_location}`;
    },
    getProfileLink: user => {
      // implement profile view below listing
      return;
    },
    getProfilePhone: user => {
      let _phone = user.phoneNumber;
      return _phone ? `tel:${_phone}` : "";
    },
    getProfileEmail: user => {
      let _email = user.email;
      return _email ? `mailto:${_email}` : "";
    }
  },
  computed: {
    listingImages: function() {
      return this.id ? this.listing.images : this.images;
    },
    listingImageLength: function() {
      return this.id
        ? (this.listing.images && this.listing.images.length) || 0
        : this.images.length;
    },
    listingTitle: function() {
      return this.id ? this.listing.title : this.title;
    },
    listingLocation: function() {
      return this.id ? this.listing.location : this.location;
    },
    listingPrice: function() {
      return this.id ? this.listing.price : this.price;
    },
    listingUser: function() {
      return this.id ? this.listing.user : this.user;
    },
    listingBedCount: function() {
      return this.id ? this.listing.bed_count : this.bed_count;
    },
    listingSpaceType: function() {
      return this.id ? this.listing.space_type : this.space_type;
    },
    listingPriceType: function() {
      return this.id ? this.listing.price_type : this.price_type;
    },
    formattedPrice: function() {
      let _value = +(this.id ? this.listing.price : this.price);
      if (!_value || !isNumber(_value)) {
        return this.getPlaceholder("price");
      }

      let _amount = _value.toFixed(2).split(".");

      return `${this.unit} ${this.thousandSeparated(_amount[0])}.<small>${
        _amount[1]
      }</small>`;
    },
    bedCountSummary: function() {
      if (!this.listingBedCount) {
        return 0;
      }
      let _total = this.listingBedCount.reduce((acc, curr) => {
        return acc + +curr.count;
      }, 0);

      return _total == 1 ? "1 bed" : `${_total} beds`;
    }
  },
  mounted: function() {
    let vm = this;
    if (this.id) {
      db.firestore
        .collection(`listings`)
        .doc(this.id)
        .get()
        .then(function(doc) {
          let _data = doc.data();

          if (!_data) {
            vm.$router.replace({ name: "notFound" });
            return;
          }
          vm.listing = _data;
          vm.$forceUpdate();
        })
        .catch(function(err) {
          vm.$router.replace({ name: "notFound" });
        });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "~/scss/variables.scss";
.listing-item {
  border-radius: $default-border-radius;
  overflow: hidden;
  width: 100%;
  margin: 1em 0;
  box-shadow: $box-shadow-light;
}
.image-grid {
  background: $border-light;
  display: grid;
  height: 400px;
  min-width: 400px;
  grid-template-columns: 4fr 1fr;
  grid-gap: 4px;

  &.has-images {
    background: $light;
  }

  .image {
    background: lighten($border-light, 30) center;
    background-size: cover;

    &:first-child {
      grid-row: 1 / 4;
    }
  }
}
.desc-grid {
  display: flex;
  border: $border-light solid thin;
  border-radius: 0 0 $default-border-radius $default-border-radius;
  border-top: none;
  padding: 1em;

  .left {
    flex-grow: 2;
    .title {
      color: $default;
      margin: 0;
      margin-bottom: 0.3em;
      font-size: 1.5em;
      display: block;

      &::first-letter {
        text-transform: uppercase;
      }
    }
    .location {
      vertical-align: middle;
      text-transform: capitalize;
      display: inline-block;
      font-size: 1em;
      color: rgba($default, 0.9);
      margin-right: 1em;

      i {
        padding-right: 0.3em;
      }

      .location-link {
        color: $default;
        font-weight: normal;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .hosted-by {
      vertical-align: middle;
      display: inline-flex;
      align-items: center;
      font-size: 1em;
      color: rgba($default, 0.9);

      i.icon-user {
        padding-right: 0.3em;
      }

      small {
        opacity: 0.8;
      }

      .hosted-by-link {
        color: $default;
        font-weight: normal;
        text-decoration: none;
        margin-right: 0.5em;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
      }
      .hosted-by-call {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        max-width: 90px;
        text-align: center;
        margin-top: 0.2em;
        i {
          font-size: 1.2em !important;
          margin-right: 0.3em;
        }
      }
    }
  }
  .right {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    .price {
      color: $accent;
      font-size: 1.5em;
      margin-bottom: 0.3em;
    }
    .settings {
      opacity: 0.8;
      margin-left: 1em;

      span {
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;

    .left {
    }
    .right {
      margin-top: 1em;
      align-items: flex-start;

      .settings {
        margin-left: 0em;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .left {
      .location,
      .hosted-by {
        display: block !important;
      }

      .hosted-by {
        margin-left: 0em;
        margin-top: 0.3em;

        i.icon-user {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
