<template>
  <div class="auth content">
    <transition name="mystay-transition" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "Welcome to Your Vue.js App!"
    };
  }
};
</script>

<style lang="scss">
.content.auth {
  height: calc(100vh - 4em);

  .auth-center-plate {
    margin-top: -1.5em;
    text-align: left;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;

    form {
      width: 100%;
      min-width: 320px;

      .call-to-action {
        margin: 1em 0;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 4em 1em;

      form {
        padding: 4em 5vw;
      }
    }
  }
}
</style>
