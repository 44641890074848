import Vue from 'vue';
import Router from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';

import home from '~/js/components/home';

import listings from '~/js/components/listings';
import allListings from '~/js/components/listings/allListings';
import singleListing from '~/js/components/listings/components/singleListing';

import auth from '~/js/components/auth';
import auth_signIn from '~/js/components/auth/signin';
import auth_signUp from '~/js/components/auth/signup';

import dashboard from '~/js/components/dashboard';
import dashboard_myListings from '~/js/components/dashboard/myListings';
import dashboard_createListing from '~/js/components/dashboard/createListing';
import dashboard_myProfile from '~/js/components/dashboard/myProfile';

import notFound from '~/js/components/notFound';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
      path: '/listings',
      name: 'listings',
      component: listings,
      children: [
        {
          path: '',
          name: 'allListings',
          component: allListings,
          props: route => {
            return { filters: route.query };
          }
        },
        {
          path: ':id',
          name: 'singleListing',
          component: singleListing,
          props: true
        }
      ]
    },

    {
      path: '/dashboard',
      name: 'dashboard',
      component: dashboard,
      meta: {
        requiresAuth: true
      },
      redirect: 'dashboard/myListings',
      children: [
        {
          path: 'createListing',
          name: 'createListing',
          component: dashboard_createListing
        },
        {
          path: 'editListing/:id',
          name: 'editListing',
          component: dashboard_createListing,
          props: true
        },
        {
          path: 'myListings',
          name: 'myListings',
          component: dashboard_myListings
        },
        {
          path: 'myProfile',
          name: 'myProfile',
          component: dashboard_myProfile
        }
      ]
    },
    {
      path: '/auth',
      component: auth,
      children: [
        {
          path: 'signin',
          name: 'signin',
          component: auth_signIn
        },
        {
          path: 'signup',
          name: 'signup',
          component: auth_signUp
        },
        {
          path: '*',
          redirect: 'signin'
        }
      ]
    },
    {
      path: '*',
      name: 'notFound',
      component: notFound
    }
  ]
});

router.beforeEach((to, from, next) => {
  let currentUser = firebase.auth().currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    if (currentUser) {
      next();
    } else {
      next({
        name: 'signin',
        query: { redirect: to.fullPath }
      });
    }
  }

  next();
});

export default router;
