import Notify from './notify.vue';

export default {
  install(Vue, options = {}) {
    // create constructor
    let VueNotify = Vue.extend(Notify);
    VueNotify.options = Object.assign(VueNotify.options, options);

    // create an instance of Notify and mount it on an element
    let container = document.createElement('div');
    let body = document.querySelector('body').appendChild(container);

    let Notifier = new VueNotify().$mount(body);

    Vue.$notify = Vue.prototype.$notify = (type, title, message, id) => {
      Notifier.showMessage(type, title, message, id);
    };
  }
};
