<template>
  <div class="notification-wrapper">
    <div class="notification-bg" :class="{active: isModal}" @click="removeMessage()"></div>
    <transition-group name="fade">
      <div
        class="notification"
        :class="n.type"
        v-for="(n, i) in notifications"
        :key="n.id"
        :style="{transform: getTransform(i), opacity: getOpacity(i)}"
        @click="removeMessage()"
      >
        <span class="icon-wrapper">
          <i class="icon" :class="getIcon(n.type)"></i>
        </span>
        <span class="title">{{n.title}}</span>
        <div class="message">{{n.message}}</div>
      </div>
    </transition-group>
  </div>
</template>



<script>
import { remove, findIndex } from "lodash";
export default {
  data: () => ({
    isModal: false,
    notifications: []
  }),

  methods: {
    showMessage: function(type, title, message, _id) {
      let nt = this.notifications;
      let id = _id || +new Date();
      let _newMessage = {
        id,
        type,
        title,
        message
      };

      let _existing = remove(nt, o => o.id === id);

      _existing &&
        (_newMessage = {
          ..._existing,
          ..._newMessage
        });

      nt.push(_newMessage);

      if (type == "error") {
        this.isModal = true;
      } else {
        let vm = this;
        setTimeout(function() {
          vm.removeMessage(id);
        }, 5000);
      }
    },
    removeMessage: function(id) {
      if (!id) {
        this.notifications.pop();
      } else {
        let _index = findIndex(this.notifications, o => o.id === id);
        _index >= 0 && this.notifications.splice(_index, 1);
      }

      !this.hasModalNotifications() && (this.isModal = false);
    },
    hasModalNotifications: function() {
      let _modalNotifications = findIndex(
        this.notifications,
        o => o.type == "error"
      );

      if (_modalNotifications >= 0) {
        return true;
      }

      return false;
    },
    getTransform: function(i) {
      let n = this.notifications.length;
      let r = (i + 1) / n;

      let _scale = 0.9 + 0.1 * r;
      let _translateY = (1 - r) * 20;

      return `scale(${_scale}) translate(-50%, ${_translateY}%)`;
    },
    getOpacity: function(i) {
      let len = this.notifications.length;
      let n = Math.min(4, this.notifications.length);
      let r = (i - len + n + 1) / n;

      if (len - i > n) return 0;
      return r;
    },
    getIcon: function(type) {
      switch (type) {
        case "success":
          return "icon-check-circle";
        case "error":
          return "icon-alert-circle";
        case "warning":
          return "icon-alert-triangle";
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import "~/scss/variables.scss";

.notification-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2147483638;

  .notification-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    background: rgba($default, 0.8);
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s;
    cursor: pointer;

    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }

  .notification {
    background: $accent;
    color: $light;
    max-width: 400px;
    padding: 1em 2em;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    box-shadow: $box-shadow-hover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: 0% 120px;
    margin-top: -100px;
    padding-top: calc(100px + 0.5em);
    transition: all 0.3s;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      min-width: calc(100% - 2em);
    }

    &.error {
      background-color: $error;
    }
    &.warning {
      background-color: $warning;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.3em;
      padding: 0.3em;

      i {
        font-size: 2.5em;
      }
    }
    .title {
      font-size: 1.2em;
      text-align: center;
    }
    .message {
      opacity: 0.8;
      font-size: 0.9em;
      text-align: center;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s !important;
}
.fade-enter,
.fade-leave-to {
  transition: all 1s !important;
  transform: scale(1) translate(-50%, -100%) !important;
}
</style>