<template>
  <div class="content-home">
    <div class="center-plate">
      <div class="hgroup">
        <img class="logo" src="~/img/logo.svg" alt="myStay.lk logo">
        <h1>
          <span class="dark">myStay</span><span class="gray">.lk</span>
        </h1>
        <h3>{{ isSeller ? sellerMessage : viewerMessage }}</h3>
      </div>
      <router-link
        class="btn"
        :to="{name: isSeller ? sellerActionRoute : viewerActionRoute}"
      >{{isSeller ? sellerAction : viewerAction}}</router-link>
    </div>
    <div class="footer">
      <p>
        We're working at the backstage on some improvements to help you find your perfect stay.
        <template
          v-if="!currentUser"
        >
          <br>Until we are ready to go, you can
          <router-link :to="{name: 'signup'}">sign up here.</router-link>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import firebase from "../helpers/firebase";

export default {
  name: "home",
  data: () => {
    return {
      isSeller: true,
      currentUser: firebase.auth().currentUser,

      viewerMessage: "Find the perfect place for you, for your perfect stay.",
      sellerMessage: "Rent your free space & earn some extra cash",

      viewerAction: "Explore Listing",
      sellerAction: "Create Listing",

      viewerActionRoute: "listings",
      sellerActionRoute: "createListing"
    };
  }
};
</script>

<style lang="scss" scoped>
.content-home {
  height: calc(100vh - 4em);
  min-height: 450px;
  display: flex;
  flex-direction: column;

  .center-plate {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    img.logo {
      width: 160px;
      margin-bottom: 1em;
    }

    .btn {
      margin-top: 1em;
    }
  }

  .footer {
    p {
      text-align: center;
      opacity: 0.6;
      padding: 1em;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9em;

    .center-plate {
      padding: 2em;

      .logo {
        max-width: 80%;
      }
    }
  }
}
</style>
