<template>
  <div class="auth-center-plate">
    <form @submit.prevent="login">
      <div class="hgroup">
        <h1>Sign in</h1>
        <h3>
          Welcome to
          <span class="dark">myStay</span>.lk
        </h3>
      </div>
      <label>
        <span class="desc">Email</span>
        <input class="large" type="text" placeholder="Your email" v-model="email">
      </label>
      <label>
        <span class="desc">Password</span>
        <input class="large" type="password" placeholder="Your password" v-model="password">
      </label>
      <input class="call-to-action" type="submit" value="Sign in" @click="login">
      <p>
        Not signed up?
        <router-link :to="{name: 'signup'}">Create a new account</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "signin",
  data: () => {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    login: function() {
      let vm = this;
      vm.$showLoader("auth_sign_in");
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          function(user) {
            vm.$notify(
              "success",
              "Successfully signed in",
              "You have been successfully signed in",
              "auth_sign_in"
            );

            if (vm.$route.query.redirect) {
              vm.$router.replace(vm.$route.query.redirect);
            } else {
              vm.$router.replace({ name: "dashboard" });
            }
            vm.$hideLoader("auth_sign_in");
          },
          function(err) {
            vm.$notify(
              "error",
              "Failed to sign in",
              err.message || "There were some errors while sigining you in",
              "auth_sign_in"
            );
            vm.$hideLoader("auth_sign_in");
          }
        );
    }
  }
};
</script>

<style lang="scss">
</style>
