<template>
  <div class="content-notFound">
    <div class="center-plate">
      <div class="hgroup">
        <img class="logo" src="~/img/error.svg" alt="myStay.lk logo" />
        <h1><span class="gray">The geeks call it a </span> 404</h1>
        <h3>
          The resource you were looking for was not found.
        </h3>
      </div>
    </div>
    <div class="footer">
      <p>
        This should not be what you're looking at, it should be reachable from  <router-link to="/">myStay.lk</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
};
</script>

<style lang="scss" scoped>
.content-notFound {
  height: calc(100vh - 4em);
  display: flex;
  flex-direction: column;
  
  .center-plate {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    img.logo {
      width: 160px;
      padding-bottom: 1em;
    }
  }

  .footer {
    p {
      text-align: center;
      opacity: 0.6;
      padding: 1em;
    }
  }
}
</style>
