<template>
  <div class="loader-wrapper">
    <svg class="loader" :viewBox="`0 0 2 ${windowWidth}`" :class="{visible: isInProgress}">
      <path
        class="swayer"
        stroke-width="2"
        :d="`M0,0H${windowWidth}`"
        :style="`stroke-dasharray: ${windowWidth}; stroke-dashoffset: ${dashOffset}`"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  progressAnimInterval: null,
  data: () => ({
    isInProgress: false,
    windowWidth: window.innerWidth,
    dashOffset: 0,
    pendingProgresses: []
  }),

  methods: {
    showLoader: function(id) {
      let vm = this;
      vm.windowWidth = window.innerWidth;
      vm.isInProgress = true;
      vm.pendingProgresses.push(id);

      if (!vm.progressAnimInterval) {
        vm.dashOffset = 0;
        vm.progressAnimInterval = setInterval(() => {
          vm.toggleDashOffset();
        }, 1000);
      }
    },
    hideLoader: function(id) {
      let _progresses = this.pendingProgresses;
      _progresses.splice(_progresses.indexOf(id), 1);
      if(this.progressAnimInterval && _progresses.length == 0) {
        this.isInProgress = false;
        clearInterval(this.progressAnimInterval);
        this.progressAnimInterval = null;
        let vm = this;
        setTimeout(function(){
          if(_progresses.length == 0){
            vm.dashOffset = 0;
          }
        }, 1000);
      }
    },
    toggleDashOffset: function() {
      this.dashOffset -= this.windowWidth;
    }
  },
  destroyed() {
    clearInterval(this.progressAnimInterval);
  }
};
</script>


<style lang="scss" scoped>
@import "~/scss/variables.scss";

.loader-wrapper {
  position: fixed;
  top: calc(4em - 1px);
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 2147483637;
  pointer-events: none;

  .loader {
    width: 100%;
    height: 2px;
    display: block;
    opacity: 0;
    transition: opacity 1s;

    &.visible {
      opacity: 1;
    }

    .swayer {
      position: relative;
      stroke: $accent;
      stroke-width: 3px;
      fill: transparent;
      height: 2px;
      transition: stroke-dashoffset 1s;
    }
  }
}
</style>