<template>
  <div>
    <div class="hgroup">
      <h2>All listings</h2>
    </div>
    <div class="centered faded block" v-if="hasNoListingsFetched">
      <i class="icon icon-flag icon-large"></i>
      <p>Looking for your listings... 🧐</p>
    </div>
    <div class="centered faded block" v-if="!hasNoListingsFetched && hasNoListings">
      <i class="icon icon-flag icon-large"></i>
      <p>There's nothing here yet. But why? 🤔</p>
      <router-link class="btn small" :to="{name: 'createListing'}">Create a listing now</router-link>
    </div>
    <div class="listings-wrapper">
      <ins
        class="adsbygoogle"
        style="display:block"
        data-ad-format="fluid"
        data-ad-layout-key="-fb+5w+4e-db+86"
        data-ad-client="ca-pub-4300362286707853"
        data-ad-slot="6363914054"
      ></ins>
      <mini-listing
        :key="id"
        v-for="(listing, id) in listings"
        :images="listing.images"
        :title="listing.title"
        :location="listing.location"
        :price="+listing.price"
        :price_type="listing.price_type"
        :space_type="listing.space_type"
        :bed_count="listing.bed_count"
      >
        <div class="actions">
          <router-link class="btn small" :to="{name: 'singleListing', params: {id}}">View listing</router-link>
        </div>
      </mini-listing>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";

import db from "~/js/helpers/db";

import miniListing from "../listings/components/miniListing.vue";

export default {
  name: "listings",
  components: {
    miniListing
  },
  props: ["filters"],
  data: () => {
    return {
      listings: {},
      hasNoListingsFetched: true
    };
  },
  computed: {
    hasNoListings: function() {
      return isEmpty(this.listings);
    }
  },
  watch: {
    filters: function() {
      console.log("props", this.filters);
      let vm = this;
      vm.$showLoader("all_listings_filter");
      vm.hasNoListingsFetched = true;
      vm.listings = {};

      db.firestore
        .collection(`listings`)
        .where("price", ">=", this.filters.min)
        .where("price", "<=", this.filters.max)
        .orderBy("price", "asc")
        .orderBy("updated_at", "desc")
        .onSnapshot(function(querySnapshot) {
          console.log("querySnapshot", querySnapshot);
          querySnapshot.forEach(function(doc) {
            console.log("doc.data()", doc.data());
            vm.$set(vm.listings, doc.id, doc.data());
          });

          vm.$hideLoader("all_listings_filter");
          vm.hasNoListingsFetched = false;
        });
    }
  },
  mounted: function() {
    let vm = this;
    vm.$showLoader("all_listings");
    db.firestore
      .collection(`listings`)
      .orderBy("updated_at", "desc")
      .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          vm.$set(vm.listings, doc.id, doc.data());
        });

        vm.$hideLoader("all_listings");
        vm.hasNoListingsFetched = false;
      });
  }
};
</script>


<style lang="scss" scoped>
.listings-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  margin-bottom: 3em;
}
</style>
