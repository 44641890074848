<template>
  <div class="upload-item" ref="uploadItem" @dragstart="emitEvent('dragstart', originalIndex)" @dragend="emitEvent('dragend', originalIndex)" @dragover="emitEvent('dragover', originalIndex)">
    <div class="file-info" :class="status">
      <i class="icon icon-image"></i>
      <a :href="link ? link: 'javascript:void(0)'">
        <span class="filename" ref="fileName" v-html="getTruncated(file.name)"></span>
      </a>
      <i v-show="status === 'complete'" class="icon icon-check-circle accent"></i>
    </div>
    <div class="actions">
      <div class="status" :class="status">{{showProgress()}}</div>
      <div class="action" :class="status">
        <i class="icon icon-x" @click="emitEvent('remove')"></i>
      </div>
    </div>
    <div class="bar">
      <div class="progress" :class="status" :style="{width: `${progress}%`}"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    progress: Number,
    file: [File, Object],
    status: String,
    link: String,
    index: Number,
  },
  data: () => ({
    truncated: false,
    extraWidth: 0,
    originalIndex: null,
  }),
  methods: {
    emitEvent: function(type, event) {
      this.$emit(type, event);
    },
    showProgress: function() {
      if (!this.status || this.status == "pending") {
        return "pending";
      }
      if (this.status == "complete") {
        return "uploaded";
      }
      return Math.ceil(this.progress) + "%";
    },
    getTruncated: function(text) {
      if (this.truncated && text.length > 18) {
        let _truncatedChars = Math.floor(this.extraWidth / 10) + 6;
        let _truncatedIndexEnd = text.length - 6;
        let _truncatedIndexStart = 15;

        let _start = text.substr(0, _truncatedIndexStart);
        let _end = text.substr(_truncatedIndexEnd);
        return `<div class="start">${_start}</div><div class="ellipsis"></div><div class="end">${_end}</div>`;
      }
      return text;
    }
  },
  mounted: function() {
    let _name_w = this.$refs.fileName.clientWidth;
    let _container_w = this.$refs.uploadItem.clientWidth;

    if (_name_w > 90) {
      this.truncated = true;
      this.extraWidth = _name_w - 90;
    }

    this.originalIndex = this.index;
  }
};
</script>

<style lang="scss">
.upload-item {
  cursor: move;
  .file-info {
    a {
      width: 100%;

      .filename {
        flex-grow: 1;
        justify-content: stretch;
        .start {
          white-space: nowrap;
        }
        .ellipsis {
          flex-grow: 1;
          overflow: hidden;
          position: relative;

          &::after {
            content: "......................................";
            position: absolute;
            white-space: nowrap;
          }
        }
        .end {
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~/scss/variables.scss";
$upload-bar-width: 3px;

.upload-item {
  display: grid;
  grid-template-columns: 200px 100px;
  margin: 1em 0;

  .file-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 0.5em;

    a,
    a:link {
      text-decoration: none;
      color: $default;
      font-weight: normal;
    }

    &.complete {
      color: $accent;

      .filename {
        color: $accent;
      }
    }

    i.icon-image {
      color: $default;
      margin-right: 0.5em;
    }
    i.icon-check-circle {
      margin-left: 0.5em;
    }

    .filename {
      display: flex;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: auto;

    .status {
      font-size: 0.8em;
      margin-right: 0.5em;
      text-transform: uppercase;
      color: $faded;
    }
    .action {
      font-size: 1.4em;
      cursor: pointer;
      color: $faded;

      &:hover {
        color: $default;
      }
    }
  }
  .bar {
    background: $border;
    height: $upload-bar-width;
    grid-column: 1/3;
    margin-top: $upload-bar-width;

    .progress {
      height: $upload-bar-width;
      background: $accent;
      width: 0;
      transition: width 0.3s ease-in-out;
    }
  }
}
</style>
