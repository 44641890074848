<template>
  <div class="listings">
    <div class="hgroup">
      <h2 class="title-case">{{listingMode}} Listing</h2>
      <p class="faded title-case">{{listingMode}} your listing at mystay.lk</p>
    </div>
    <div class="listing-wrapper">
      <div class="edit-info">
        <h3>Listing Details</h3>
        <form @submit.prevent="fileUpload">
          <transition name="mystay-list">
            <label v-if="!hasMaxFileUploads()">
              <span class="desc">Images</span>
              <input type="file" @change="fileChanged" accept="image/*" multiple>
            </label>
          </transition>
          <transition name="mystay-list">
            <div class="upload-image-list" v-if="hasUploadedFiles()">
              <p class="faded">
                <span>({{imageFilesUploaded.length}}/{{imageFilesPending.length}}) Images uploaded</span>
                <span>(Drag to re-order)</span>
              </p>
              <transition-group name="mystay-list">
                <upload-item
                  :index="file.index"
                  draggable="true"
                  :key="file.name + file.lastModified"
                  :file="file"
                  :link="imageFilesUploaded[getOrderedIndex(i)]"
                  :progress="fileUploadProgress[getOrderedIndex(i)]"
                  :status="fileUploadStatus[getOrderedIndex(i)]"
                  v-for="(file, i) in getOrdered(imageFilesPending)"
                  @remove="removeFileUpload(getOrderedIndex(i))"
                  @dragstart="uploadedItemDragStart($event)"
                  @drag="uploadedItemDrag($event)"
                  @dragover="uploadedItemDragover($event)"
                  @dragend="uploadedItemDragEnd($event)"
                ></upload-item>
              </transition-group>
            </div>
          </transition>
          <transition name="mystay-list">
            <input class="small" type="submit" v-if="hasUploadedFiles()" :value="'Upload image(s)'">
          </transition>
        </form>

        <form @submit.prevent="createListing">
          <label>
            <span class="desc">Title</span>
            <input type="text" placeholder="Title of listing (eg: Ishan's place)" v-model="title">
          </label>
          <label>
            <span class="desc">Location</span>
            <input type="text" placeholder="Location" v-model="location">
          </label>
          <label>
            <span class="desc">Price</span>
            <div class="input-row">
              <input type="number" placeholder="Price" v-model="price" step="0.01">
              <select v-model="price_type">
                <option :value="val" :key="val" v-for="val in PRICE_TYPES">{{ val }}</option>
              </select>
            </div>
          </label>
          <label>
            <span class="desc">Setting</span>
            <select v-model="space_type">
              <option :value="val" :key="val" v-for="val in SPACE_TYPES">{{ val }}</option>
            </select>
          </label>
          <label>
            <span class="desc">Bed count</span>
            <div class="input-row" :key="bedding.type" v-for="(bedding, b_i) in bed_count">
              <input type="number" v-model="bedding.count" placeholder="0" min="1">
              <select v-model="bedding.type">
                <option :value="val" :key="val" v-for="val in getRemainingBedTypes(b_i)">{{ val }}</option>
              </select>
              <div class="grouped">
                <button
                  class="btn btn-inline"
                  :disabled="disableAddingBedTypes"
                  @click.prevent="addBedType"
                  :style="{borderRight: disableRemovingBedTypes ? 'solid thin #00d388' : 'none'}"
                >
                  <i class="icon icon-plus"></i>
                </button>
                <button
                  class="btn btn-inline"
                  :disabled="disableRemovingBedTypes"
                  @click.prevent="removeBedType(b_i)"
                  :style="{borderLeft: disableRemovingBedTypes ? 'none' : ''}"
                >
                  <i class="icon icon-minus"></i>
                </button>
              </div>
            </div>
          </label>
          <input
            class="small"
            type="submit"
            :value="`${listingMode == 'edit' ? 'Update' : 'Create'} Listing`"
          >
        </form>
      </div>
      <div class="preview-info">
        <h3>Listing Preview</h3>
        <single-listing
          :images="getOrdered(getBlobURLs)"
          :title="title"
          :location="location"
          :price="+price"
          :price_type="price_type"
          :space_type="space_type"
          :bed_count="bed_count"
          :user="user"
        ></single-listing>
        <div v-for="(url, index) in imageURLs" :key="index" v-show="false">
          <canvas width="800" height="450" v-show-image="{url: url, index: index }"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "~/js/helpers/db";
import storage from "~/js/helpers/storage";
import logoSM from "~/img/logo-sm.png";
import { PRICE_TYPES, SPACE_TYPES, BED_TYPES } from "~/js/data";

import singleListing from "../listings/components/singleListing.vue";
import uploadItem from "./components/uploadItem.vue";

import { each, keys } from "lodash";

export default {
  name: "createListing",
  components: {
    singleListing,
    uploadItem
  },
  props: {
    id: String
  },
  data() {
    return {
      listingMode: this.id ? "edit" : "create",
      listing: {},

      title: "",
      location: "",
      price: 0,
      price_type: "Per night",
      space_type: "Private room",
      bed_count: [
        {
          type: "Single",
          count: 1
        }
      ],

      imageFilesPending: [],
      imageFilesPendingBlobs: [],
      imageFilesUploaded: [],
      imageUploadsChanged: false,

      maxFilesAllowed: 4,
      watermarkLogo: null,

      fileUploadStatus: [],
      fileUploadProgress: [],

      currentDragIndex: null,
      currentDragOverIndex: null,

      canvasCacheCheck: {},

      uploadOrderMapping: {},

      PRICE_TYPES: PRICE_TYPES,
      SPACE_TYPES: SPACE_TYPES,
      BED_TYPES: BED_TYPES,

      user: {}
    };
  },
  directives: {
    showImage: function(el, binding, vnode) {
      let vm = vnode.context;
      let _url = binding.value.url;
      let _index = binding.value.index;

      if (vm.canvasCacheCheck[_url]) {
        return;
      }
      vm.canvasCacheCheck[_url] = true;

      if (_url.toString().indexOf("firebasestorage") >= 0) {
        // push the url instead of blob, for already uploaded files
        vm.$set(
          vm.imageFilesPendingBlobs,
          _index,
          vm.imageFilesUploaded[_index]
        );
        return;
      }

      let _canvas = el;
      _canvas.width = 800;
      _canvas.height = 450;

      let _image = new Image();
      _image.addEventListener("load", function() {
        let _ctx = _canvas.getContext("2d");
        let _src_w = _image.width;
        let _src_h = _image.height;

        let _start_x = 0;
        let _start_y = 0;

        let _image_ratio = _src_w / _src_h;
        let _required_ratio = 800 / 450;

        if (_image_ratio > _required_ratio) {
          _src_w = _src_h * _required_ratio;
          _start_x = (_image.width - _src_w) / 2;
        } else {
          _src_h = _src_w / _required_ratio;
          _start_y = (_image.height - _src_h) / 2;
        }

        _ctx.drawImage(
          _image,
          _start_x,
          _start_y,
          _src_w,
          _src_h,
          0,
          0,
          800,
          450
        );

        _ctx.globalAlpha = 0.6;
        vm.drawWatermark(_ctx, _canvas, "ligther");
        _ctx.globalAlpha = 0.8;
        vm.drawWatermark(_ctx, _canvas, "overlay");

        _canvas.toBlob(
          function(_blob) {
            vm.$set(vm.imageFilesPendingBlobs, _index, _blob);
          },
          "images/jpeg",
          0.4
        );
      });
      _image.src = _url;
    }
  },
  methods: {
    createListing: function() {
      let _title = this.title;
      let _location = this.location;
      let _price = +this.price;
      let _price_type = this.price_type;
      let _space_type = this.space_type;
      let _bed_count = this.bed_count;
      let _images = this.getOrdered(this.imageFilesUploaded);
      let _user = JSON.parse(JSON.stringify(this.user));

      if (!this.imageUploadsChanged) {
        this.$notify(
          "error",
          "An image would look nice!",
          "At least 1 image needs to be uploaded",
          "create_listing_image_needed"
        );
        // return;
      }

      let _missingFields = [];
      !_title && _missingFields.push("title");
      !_location && _missingFields.push("location");
      (!_price || !_price_type) && _missingFields.push("price");
      !_space_type && _missingFields.push("space type");
      !_bed_count.length && _missingFields.push("bed count");

      if (_missingFields.length > 0) {
        this.showMissingFieldError(_missingFields);
        return;
      }

      if (this.listingMode == "edit") {
        db.update(
          "listings",
          this.id,
          {
            title: _title,
            location: _location,
            price: +_price,
            images: _images,
            price_type: _price_type,
            space_type: _space_type,
            bed_count: _bed_count,
            user: _user
          },
          err => {
            if (!err) {
              this.$notify(
                "success",
                "Listing updated",
                "Your listing has been successfully updated",
                "create_listing"
              );
              this.resetNewListing();
            } else {
              this.$notify(
                "error",
                "Failed to updated listing",
                "Something went wrong while updating your listing",
                "create_listing"
              );
            }
          }
        );
      } else {
        db.add(
          "listings",
          {
            title: _title,
            location: _location,
            price: +_price,
            images: _images,
            price_type: _price_type,
            space_type: _space_type,
            bed_count: _bed_count,
            user: _user
          },
          err => {
            if (!err) {
              this.$notify(
                "success",
                "Listing created",
                "Your listing has been successfully created",
                "create_listing"
              );

              this.resetNewListing();
            } else {
              this.$notify(
                "error",
                "Failed to create listing",
                "Something went wrong while creating your listing",
                "create_listing"
              );
            }
          }
        );
      }
    },
    showMissingFieldError: function(fields) {
      this.$notify(
        "error",
        "Required fields are missing",
        `Please fill in the required ${fields.join(", ")} fields`,
        "create_listing"
      );
    },
    resetNewListing: function() {
      this.title = "";
      this.location = "";
      this.price = 0;
      this.imageFilesPending = [];
      this.imageFilesPendingBlobs = [];
      this.imageFilesUploaded = [];
      this.imageUploadsChanged = false;
      this.fileUploadStatus = [];
      this.fileUploadProgress = [];
      this.canvasCacheCheck = {};
      this.uploadOrderMapping = {};

      this.$router.replace({ name: "myListings" });
    },
    fileChanged: function(e) {
      let _remainingSlots =
        this.maxFilesAllowed - this.imageFilesPending.length;
      let files = [...e.target.files];
      files.splice(_remainingSlots);
      _remainingSlots && this.imageFilesPending.push(...files);

      if (_remainingSlots < files.length) {
        this.$notify(
          "warning",
          `Only ${this.maxFilesAllowed} images will appear`,
          `Currently only ${
            this.maxFilesAllowed
          } images are allowed to be uploaded`,
          "create_listing_image_max_reached"
        );
        this.imageFilesPending.splice(this.maxFilesAllowed);
        return;
      }

      each(this.imageFilesPending, (o, i) => {
        typeof this.uploadOrderMapping[i] == "undefined" &&
          this.$set(this.uploadOrderMapping, i, i);
        o.index = i;
      });

      this.imageUploadsChanged = false;
    },
    fileUpload: function(e) {
      let vm = this;

      let _files =
        this.imageFilesPendingBlobs.length > 0
          ? this.imageFilesPendingBlobs
          : [];

      if (_files.length) {
        each(_files, (pendingFile, i) => {
          if (vm.imageFilesUploaded[i]) {
            return;
          }
          vm.$set(vm.fileUploadStatus, i, "active");
          vm.$set(vm.fileUploadProgress, i, 0);

          storage.upload(
            pendingFile,
            url => {
              vm.$set(vm.imageFilesUploaded, i, url);
              vm.$set(vm.fileUploadStatus, i, "complete");
              vm.$set(vm.fileUploadProgress, i, 100);

              if (vm.imageFilesUploaded.length == vm.imageFilesPending.length) {
                this.imageUploadsChanged = true;
                this.$notify(
                  "success",
                  "Images uploaded",
                  "You can now continue to create the listing",
                  "create_listing_image_uploaded"
                );
              }
            },
            progress => {
              vm.$set(vm.fileUploadStatus, i, "active");
              vm.$set(vm.fileUploadProgress, i, progress);
            }
          );
        });
      }
    },
    removeFileUpload: function(i) {
      this.$delete(this.fileUploadStatus, i);
      this.$delete(this.fileUploadProgress, i);
      this.$delete(this.imageFilesPending, i);
      this.$delete(this.imageFilesPendingBlobs, i);
      this.$delete(this.imageFilesUploaded, i);
      this.$delete(this.uploadOrderMapping, i);

      this.fixUploadOrderMapping(i);
    },
    fixUploadOrderMapping: function(index) {
      let _prevOrderMapping = this.uploadOrderMapping;
      let _newOrderMapping = {};

      let _keys = keys(_prevOrderMapping);
      _keys.sort();

      each(_keys, (key, i) => {
        let _order = _prevOrderMapping[key];
        if (_order > index) {
          _order--;
        }
        _newOrderMapping[i] = _order;
      });

      this.uploadOrderMapping = _newOrderMapping;
    },
    drawWatermark: function(_ctx, _canvas, _compositeMode) {
      let _cw = _canvas.width;
      let _ch = _canvas.height;

      _ctx.globalCompositeOperation = _compositeMode;
      _ctx.font = "10px 'Roboto',sans-serif";
      _ctx.fillStyle = "rgba(255,255,255,0.8)";
      _ctx.textAlign = "right";
      _ctx.fillText("UPLOADED TO MYSTAY.LK", _cw - 20, _ch - 46);

      _ctx.fillStyle = "rgba(255,255,255,0.9)";
      _ctx.font = "12px 'Roboto',sans-serif";
      _ctx.fillText(
        `by ${this.user.displayName.toUpperCase()}`,
        _cw - 20,
        _ch - 30
      );

      let _w = 160;
      let _h = 113;
      let _scaled_w = _w * 0.4;
      let _scaled_h = _h * 0.4;

      _ctx.drawImage(
        this.watermarkLogo,
        0,
        0,
        _w,
        _h,
        _cw - 20 - _scaled_w,
        _ch - _scaled_h - 66,
        _scaled_w,
        _scaled_h
      );
    },

    hasUploadedFiles: function() {
      return this.imageFilesPendingBlobs.length > 0;
    },
    hasMaxFileUploads: function() {
      return this.imageFilesPendingBlobs.length >= this.maxFilesAllowed;
    },
    uploadedItemDragStart: function(i) {
      this.currentDragIndex = i;
    },
    uploadedItemDrag: function(e) {},
    uploadedItemDragEnd: function(i, e) {
      this.currentDragIndex != this.currentDragOverIndex &&
        this.swapUploads(this.currentDragIndex, this.currentDragOverIndex);
      this.currentDragIndex = null;
      this.currentDragOverIndex = null;
    },
    uploadedItemDragover: function(i) {
      this.currentDragOverIndex = i;
    },
    swapUploads: function(from, to) {
      let _mapping = this.uploadOrderMapping;
      let _from = +from;
      let _to = +to;

      let t = _mapping[_from];
      _mapping[_from] = _mapping[_to];
      _mapping[_to] = t;

      this.$forceUpdate();
    },
    getOrdered: function(arr) {
      let _images = arr;
      let _ordered = [];

      for (let i in _images) {
        let _order = this.uploadOrderMapping[i];
        _ordered[_order] = _images[i];
      }

      return _ordered;
    },
    getOrderedIndex: function(i) {
      return this.uploadOrderMapping[i];
    },
    addBedType: function() {
      if (this.bed_count.length >= BED_TYPES.length) {
        return;
      }

      this.bed_count.push({ type: this.getRemainingBedTypes()[0], count: 1 });
    },

    removeBedType: function(i) {
      this.bed_count.splice(i, 1);
    },

    getRemainingBedTypes: function(skip_i) {
      let _selected = this.bed_count.map(o => o.type);
      let _remaining = this.BED_TYPES.filter(o => {
        let _index = _selected.indexOf(o);
        return _index == skip_i || _index < 0;
      });

      return _remaining;
    }
  },
  computed: {
    imageURLs: function() {
      let urls = [];

      for (let i in this.imageFilesPending) {
        try {
          urls.push(URL.createObjectURL(this.imageFilesPending[i]));
        } catch (e) {
          urls.push(this.imageFilesUploaded[i]);
        }
      }

      return urls;
    },
    getBlobURLs: function() {
      let blobs = this.imageFilesPendingBlobs;
      let _urls = [];

      for (let i in blobs) {
        try {
          _urls.push(URL.createObjectURL(blobs[i]));
        } catch (e) {
          _urls.push(blobs[i]);
        }
      }

      return _urls;
    },
    disableAddingBedTypes: function() {
      return this.bed_count.length >= this.BED_TYPES.length;
    },
    disableRemovingBedTypes: function() {
      return this.bed_count.length <= 1;
    }
  },
  mounted: function() {
    let vm = this;
    let _image = new Image();
    _image.src = logoSM;

    _image.addEventListener("load", function() {
      vm.watermarkLogo = _image;
    });

    let _user = db.getUser();
    vm.user = _user;

    if (this.listingMode == "edit") {
      db.firestore
        .collection(`listings`)
        .doc(this.id)
        .get()
        .then(function(doc) {
          let _data = doc.data();

          if (!_data) {
            vm.listingMode = "create";
            vm.$router.replace({ name: "createListing" });
            vm.$notify(
              "error",
              "Failed to edit",
              "Couldn't fetch the specific listing for editing",
              "edit_listing_load"
            );

            return;
          }
          vm.listing = _data;

          vm.title = _data.title;
          vm.location = _data.location;
          vm.price = +_data.price;
          vm.price_type = _data.price_type;
          vm.space_type = _data.space_type;
          vm.bed_count = _data.bed_count;
          vm.user = _data.user;
          vm.imageFilesPendingBlobs = [];
          vm.imageFilesUploaded = _data.images;
          vm.fileUploadProgress = Array(_data.images.length).fill(100);
          vm.fileUploadStatus = Array(_data.images.length).fill("complete");
          vm.uploadOrderMapping = Array.from(
            Array(_data.images.length),
            (x, i) => i
          );
          vm.imageFilesPending = _data.images.map((o, i) => ({
            index: i,
            name: `uploaded-image-${vm.user.displayName
              .split(" ")
              .join("-")}-${i}.png`,
            lastModified: +Date.now()
          }));

          // since images are ready when editing
          vm.imageUploadsChanged = true;

          vm.$forceUpdate();
        })
        .catch(function(err) {
          vm.$notify(
            "error",
            "Failed to load listing",
            err.message || "Couldn't find the specific listing information",
            "auth_sign_in"
          );
        });
    }
  }
};
</script>

<style lang='scss' scoped>
.listing-wrapper {
  display: flex;
  flex-wrap: wrap-reverse;

  .edit-info {
    margin-right: 2em;
    flex: 1;
    min-width: 400px;

    input {
      width: 100%;
    }

    .row-input {
      width: 100%;
    }
  }
  .preview-info {
    flex: 3;
  }
}
</style>
