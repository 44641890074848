import firebase from './firebase';

const firestore = firebase.firestore();
firestore.settings({
  timestampsInSnapshots: true
});

if (!firebase.apps.length) {
  firestore.enablePersistence();
}

const database = {
  add: function(collection, data, cb) {
    data.uid = this.getUID();
    data.created_at = firebase.firestore.FieldValue.serverTimestamp();
    data.updated_at = firebase.firestore.FieldValue.serverTimestamp();
    firestore
      .collection(collection)
      .add(data)
      .then(function(docRef) {
        cb();
      })
      .catch(function(error) {
        cb(error);
      });
  },

  get: (collection, cb) => {
    firestore
      .collection(collection)
      .get()
      .then(querySnapshot => {
        let data = {};
        querySnapshot.forEach(function(doc) {
          data[doc.id] = doc.data();
        });
        cb(null, data);
      });
  },

  update: (collection, id, data, cb) => {
    data.updated_at = firebase.firestore.FieldValue.serverTimestamp();
    firestore
      .collection(collection)
      .doc(id)
      .update(data)
      .then(function() {
        cb();
      })
      .catch(function(error) {
        cb(error);
      });
  },

  getUser: () => {
    return firebase.auth().currentUser;
  },

  getUID: () => {
    return firebase.auth().currentUser.uid;
  },

  firestore: firestore
};

export default database;
