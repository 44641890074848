import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let config = {
  apiKey: 'AIzaSyAgzlJ1CIEV_6mS3LvmbH7r4_yj9MXx0Ek',
  authDomain: 'mystay-new.firebaseapp.com',
  databaseURL: 'https://mystay-new.firebaseio.com',
  projectId: 'mystay-new',
  storageBucket: 'mystay-new.appspot.com',
  messagingSenderId: '874991227101'
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;