<template>
  <div>
    <div class="hgroup">
      <h2>My Profile</h2>
      <p class="faded">
        Update your
        <span class="accent">mystay.lk</span> user profile
      </p>
    </div>
    <div class="profile-summary">
      <div class="profile-image" v-if="photoURL" :style="{backgroundImage: `url(${photoURL})`}"></div>
      <div class="profile-image" v-else>
        <img src="~/assets/placeholder-avatar.svg" alt>
      </div>
      <div class="profile-info">
        <span class="name">{{displayName}}</span>
        <span class="email">
          {{email}}
          <span class="email-status" v-show="emailVerified">
            <i class="icon icon-check-circle"></i>
          </span>
        </span>
        <span class="last-visited">Last signed up {{lastVisited}}</span>
      </div>
      <div class="profile-stats">
        <div class="stat">
          <span class="number">{{hoursSinceLastVisited.value}}</span>
          <span class="desc">{{hoursSinceLastVisited.type}} since last sign in</span>
        </div>
        <div class="stat">
          <span class="number">{{daysSinceJoined.value}}</span>
          <span class="desc">{{daysSinceJoined.type}} since joined</span>
        </div>
        <!-- <div class="stat">
          <span class="number">24</span>
          <span class="desc">Listings posted</span>
        </div>
        <div class="stat">
          <span class="number">3600</span>
          <span class="desc">Listing views</span>
        </div>-->
      </div>
    </div>
    <div class="profile-section">
      <h4 class="section-header">Profile information</h4>
      <input type="text" v-model="displayName" placeholder="Your Name">
      <input type="text" v-model="photoURL" placeholder="URL of your profile picture">
      <input class="small" type="button" value="Update Profile" @click="updateProfile">
    </div>
    <div class="profile-section">
      <h4 class="section-header">Email information</h4>
      <input type="email" v-model="email">
      <input
        class="small"
        type="button"
        value="Verify Email"
        @click="verifyEmail"
        v-if="!emailVerified"
      >
      <span class="email-status" v-else>
        <i class="icon icon-check-circle"></i> EMAIL VERIFIED
      </span>
    </div>
    <div class="profile-section">
      <h4 class="section-header">Contact information</h4>
      <div>
        <input type="tel" v-model="phoneNumber" placeholder="Phone number" :readonly="smsRequested">
        <input
          class="small"
          type="button"
          :value="phoneNumberChangedCTA"
          :disabled="!phoneNumberChanged"
          @click="updatePhoneNumber"
        >
        <input
          class="small"
          type="button"
          value="Change Phone Number"
          v-show="smsRequested"
          @click="resetPhoneNumberUpdate"
        >
      </div>
      <div class="verification-code" v-if="verifyCodeSent">
        <input type="text" v-model="phoneNumber_verificationCode" placeholder="Verification code">
        <input class="small" type="button" value="Verify Phone number" @click="verifyPhoneNumber">
      </div>
    </div>
    <div class="profile-section">
      <h4 class="section-header">Change password</h4>
      <input type="password" v-model="newPassword" placeholder="Your new password">
      <input class="small" type="button" value="Change password" @click="changePassword">
    </div>
  </div>
</template>

<script>
import moment from "moment";
import firebase from "~/js/helpers/firebase";
import db from "~/js/helpers/db";

export default {
  data: () => {
    return {
      title: "User profile",

      displayName: null,
      photoURL: null,

      email: null,
      emailVerified: null,

      phoneNumber: null,
      original_phoneNumber: null,
      phoneNumber_verificationCode: null,

      newPassword: "",
      user: {},

      lastLoginAt: null,
      createdAt: null,

      smsRequested: false,
      verifyCodeSent: false
    };
  },
  methods: {
    updateProfile: function() {
      let vm = this;
      this.user
        .updateProfile({
          displayName: this.displayName,
          photoURL: this.photoURL
        })
        .then(function() {
          vm.$notify(
            "success",
            "Profile updated",
            "Your profile information was updated"
          );
        })
        .catch(function(e) {
          vm.$notify("error", "Failed to update profile", e.message);
        });
    },
    verifyEmail: function() {
      this.user
        .sendEmailVerification()
        .then(function() {
          // Email sent.
          vm.$notify(
            "success",
            "Verification Email sent",
            "Please check your inbox, and follow the link sent."
          );
        })
        .catch(function(e) {
          vm.$notify("error", "Failed to send verification email", e.message);
        });
    },
    changePassword: function() {
      let vm = this;
      this.user
        .updatePassword(this.newPassword)
        .then(function() {
          vm.$notify(
            "success",
            "Password changed successfully",
            "Your password has been changed successfully"
          );
        })
        .catch(function(e) {
          // An error happened.
          vm.$notify("error", "Failed to change password", e.message);
        });
    },
    resetPhoneNumberUpdate: function() {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.render().then(function(widgetId) {
          grecaptcha.reset(widgetId);
        });
      }

      this.initializeRecaptchaVerifier();
      this.smsRequested = false;
      this.verifyCodeSent = false;
    },
    initializeRecaptchaVerifier: function() {
      if (window.recaptchaVerifier) {
        return;
      }
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function(response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          }
        }
      );
    },
    updatePhoneNumber: function() {
      let vm = this;
      let _phoneNumber = this.phoneNumber;
      let _appVerifier = window.recaptchaVerifier;

      vm.smsRequested = true;

      firebase
        .auth()
        .signInWithPhoneNumber(_phoneNumber, _appVerifier)
        .then(function(confirmationResult) {
          vm.verifyCodeSent = true;
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
        })
        .catch(function(e) {
          vm.$notify("error", "Failed to update phone number", e.message);
          vm.verifyCodeSent = false;
          vm.smsRequested = false;

          // Error; SMS not sent
          // ...
        });
    },
    verifyPhoneNumber: function() {
      let vm = this;
      let _prevUser = this.user;
      let _code = this.phoneNumber_verificationCode;
      let _credentials = firebase.auth.PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        _code
      );

      if (vm.user.phoneNumber) {
        vm.user
          .updatePhoneNumber(_credentials)
          .then(function() {
            vm.$notify(
              "success",
              "Phone number updated",
              "Your phone number got updated successfully."
            );
            vm.smsRequested = false;
            vm.verifyCodeSent = false;
          })
          .catch(function(e) {
            vm.$notify("error", "Failed to update phone number", e.message);
            vm.smsRequested = false;
            vm.verifyCodeSent = false;
          });
        return;
      }

      firebase
        .auth()
        .signInAndRetrieveDataWithCredential(_credentials)
        .then(function(response) {
          var currentUser = response.user;
          // Merge prevUser and currentUser data stored in Firebase.
          // Note: How you handle this is specific to your application

          // After data is migrated delete the duplicate user
          function linkAccountAndLink() {
            vm.smsRequested = false;
            vm.verifyCodeSent = false;
            return response.user
              .delete()
              .then(function() {
                // Link the OAuth Credential to original account
                return _prevUser.linkWithCredential(_credentials);
              })
              .then(function() {
                // Sign in with the newly linked credential
                return firebase
                  .auth()
                  .signInWithCredential(_credentials)
                  .then(function() {
                    vm.$notify(
                      "success",
                      "Phone number updated",
                      "Your phone number got updated successfully."
                    );
                  });
              });
          }
          return linkAccountAndLink();
        })
        .catch(function(e) {
          vm.$notify("error", "Failed to update phone number", e.message);
        });
    }
  },
  computed: {
    lastVisited: function() {
      let _lastVisited = moment(this.lastLoginAt);
      return _lastVisited.fromNow();
    },
    hoursSinceLastVisited: function() {
      let _now = moment();
      let _lastVisited = moment(this.lastLoginAt);

      let value = _now.diff(_lastVisited, "hours");
      let type = value == 1 ? "Hour" : "Hours";
      if (value) {
        return { value, type };
      } else {
        value = _now.diff(_lastVisited, "minutes");
        type = value == 1 ? "Minute" : "Minutes";
        return { value, type };
      }
    },
    daysSinceJoined: function() {
      let _now = moment();
      let _joined = moment(this.createdAt);

      let value = _now.diff(_joined, "days");
      let type = value == 1 ? "Day" : "Days";
      if (value) {
        return { value, type };
      } else {
        value = _now.diff(_joined, "hours");
        type = value == 1 ? "Hour" : "Hours";
        return { value, type };
      }
    },
    phoneNumberChanged: function() {
      return this.phoneNumber !== this.user.phoneNumber;
    },
    phoneNumberChangedCTA: function() {
      if (!this.phoneNumberChanged) {
        return "Change phone number";
      }
      return !this.smsRequested
        ? "Update phone number"
        : "Resend verification code";
    }
  },
  mounted: function() {
    let _user = db.getUser();

    this.displayName = _user.displayName;
    this.email = _user.email;
    this.emailVerified = _user.emailVerified;
    this.phoneNumber = _user.phoneNumber;
    this.photoURL = _user.photoURL;

    this.lastLoginAt = _user.metadata.lastSignInTime;
    this.createdAt = _user.metadata.creationTime;

    this.user = _user;

    this.initializeRecaptchaVerifier();
  }
};
</script>

<style lang="scss" scoped>
@import "~/scss/variables.scss";

.profile-section {
  margin: 0 -3em;
  padding: 2em 3em;
  border-bottom: solid thin $border-light;

  &:last-child {
    border-bottom: none;
  }

  .section-header {
    margin: 0;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-size: 0.9em;
  }
}

.profile-summary {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -3em;
  padding: 2em 3em;
  border-bottom: solid thin $border-light;

  .profile-image {
    width: 120px;
    height: 120px;
    background: $background center;
    background-size: cover;
    border: $border solid thin;
    border-radius: 50%;
    margin-right: 1em;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .profile-info {
    display: flex;
    flex-direction: column;
    padding: 1em;

    .name {
      font-size: 1.5em;
    }
    .email,
    .last-visited {
      color: $faded;

      .email-status {
        font-size: 1em;
        color: $accent;
        margin-left: 0.3em;
      }
    }
    .last-visited {
      margin-top: 1em;
      font-size: 0.9em;
      opacity: 0.7;
    }
  }
  .profile-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-grow: 1;

    .stat {
      display: flex;
      flex-direction: column;
      margin-left: 2em;
      align-items: flex-end;

      .number {
        font-size: 2em;
      }
      .desc {
        text-align: right;
        color: $faded;
        font-size: 0.9em;
        max-width: 200px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .profile-stats {
      width: 100%;
      justify-content: stretch;

      .stat {
        flex-grow: 1;
        margin-top: 2em;
        margin-left: 0;
        padding: 0 1em;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: center !important;

    .profile-info {
      text-align: center;
      span.email {
        white-space: nowrap;
      }
    }

    .profile-stats {
      width: 100%;
      justify-content: stretch;

      .stat {
        flex-grow: 1;
        margin-top: 2em;
        margin-left: 0;
        padding: 0 1em;
        align-items: center;
      }
    }
  }
}
.email-status {
  font-size: 1em;
  color: $accent;
  margin-left: 1em;

  i {
    margin-right: 0.3em;
  }
}
.verification-code {
  margin-top: 1em;
}
</style>
